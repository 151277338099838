<template>
  <div id="footer-section">
    <div class="wrapper">
      <div class="footer-left">
        <div class="title">
          Contact Us
        </div>
        <div class="address">
          <span>Address:&nbsp;</span><span class="address-text">No. 35, Kengwei Avenue, Shiyan Town, <br>Bao'an District, Shenzhen, Point Gold</span>
        </div>
        <div class="tel">
           Phone:&nbsp;0755-25508906
        </div>

        <div class="desc-one">
          China National Accreditation Service for Conformity Assessment (CNAS) Laboratory Certification
        </div>
        <div class="desc-two">
          ISO 9001 – ISO 14001 – ISO 45001 Certification
        </div>

        <div class="footer-img">
          <img src="https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/footer.png" alt="" class="footer-01">
        </div>
      </div>
      <div class="footer-middle hidden-xs-only">
        <div class="quick-links">
          Quick Links
        </div>
        
        <div class="quick-link">
          <router-link to="/en/JinMingFengJewelry" class="quick-link">Core Business</router-link>
        </div>
        <div class="quick-link">
          <router-link to="/en/jewelry" class="quick-link">Product Services</router-link>
        </div>
        <div class="quick-link">
          <router-link to="/en/Announcement" class="quick-link">LBMA & LPPM</router-link>
        </div>
        <div class="quick-link">
          <router-link to="/en/CompanyProfile" class="quick-link">About Us</router-link>
        </div>
        <!-- <div class="quick-link">
          <router-link to="/en" class="quick-link">ENGLISH</router-link>
        </div> -->
      </div>
      <div class="footer-right">
        <div class="wechat">
          <img src="https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/footer-04.png" alt="" class="wechat-img">
        </div>
        <div class="wechat-text">
          WECHAT
        </div>
      </div>
    </div>
    <div style="height: 15px;"></div>
  </div>
</template>

<script>

export default {
  name: 'FooterSectionEN',
}
</script>

<style scoped lang="scss">
#footer-section {
  background-color: #132044;
  .wrapper {
    padding-right: 15%;
    padding-left: 10%;
    @media only screen and (max-width: 767px) {
    padding-left: 4%;
    padding-right: 4%;
    }
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
    .footer-left {
      color: #ffffff;
      @media only screen and (min-width: 768px) {
        width: 50%;
      }
      .title {
        @media only screen and (max-width: 767px) {
          padding-top: 5%;
          font-size: 18px;
        }
        padding-top: 5%;
        font-size: 20px;
        font-weight: bolder;
      }
      .address {
        @media only screen and (max-width: 767px) {
          padding-top: 3%;
          padding-right: 3%;
          font-size: 14px;
          display: inline-flex;
          align-items: flex-start;
        }
        font-size: 14px;
        span {
          @media only screen and (max-width: 767px) {
            vertical-align: top;
            display: inline-block;
          }
        }
        span:first-child {
          @media only screen and (max-width: 767px) {
            white-space: nowrap;
          }
        }
        .address-text {
          @media only screen and (max-width: 767px) {
            word-break: break-all;
            display: inline-block;
          }
        }
      }
      .tel {
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
        font-size: 14px;
      }

      .desc-one {
        margin-top: 2%;
        @media only screen and (max-width: 767px) {
          margin-top: 3%;
          font-size: 14px;
        }
        font-size: 14px;
      }
      .desc-two {
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
        font-size: 14px;
      }

      .footer-img {
        margin-top: 3%;
        padding-bottom: 2%;
        .footer-01 {
          @media only screen and (max-width: 767px) {
            width: 280px;
            height: 30px;
          }
          width: 70%;
          height: 38px;
        }
      }
    }
    .footer-middle {
      width: 20%;
      color: white;
      padding-top: 2.8%;
      .quick-links {
        font-size: 20px;
        font-weight: bolder;
      }
      .quick-link {
        font-size: 16px;
        font-weight: 400;
        margin-top: 5%;
        text-decoration: none;
        color: #ffffff;
      }
      .quick-link :hover{
        text-decoration: underline;
      }
    }
    .footer-right {
      color: #ffffff;
      width: 30%;
      @media only screen and (max-width: 767px) {
        margin-top: 3%;
        padding-bottom: 10%;
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .wechat {
        .wechat-img {
          width: 100px;
        }
      }
      .wechat-text {
        @media only screen and (max-width: 767px) {
          margin-top: 1%;
          border: 2px solid #ffffff;
          border-radius: 5px;
          padding-left: 5px;
          padding-right: 5px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>